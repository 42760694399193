<template>
  <div>
    <v-layout row wrap style="margin:0;margin-bottom: 10px;">
      <v-flex xs9>
        <h1>{{ client_name || "..." }}</h1>
      </v-flex>
      <v-flex xs3 style="text-align:right;">
        <v-btn @click="edit_client__dialog = true; edit_client__item = {id: client_id, name: client_name}" text><i class="fal fa-pencil"></i></v-btn>
      </v-flex>
    </v-layout>
    
    <h2 style="margin-bottom:20px;">Projects</h2>
    <div v-if="l_projects">
      <v-tabs v-model="current_tab">
        <v-tab v-for="project in tab_projects" :key="project.value">{{
          project.text
        }}</v-tab>
        <v-tab><i class="fad fa-plus"></i>New</v-tab>
      </v-tabs>
      <v-tabs-items v-model="current_tab">
        <v-tab-item
        eager
          v-for="(project, index) in tab_projects"
          :key="project.value"
        >
          <v-container>
            <v-layout>
              <v-flex xs6>
                <h3 style="margin-bottom: 10px">Details</h3>
              </v-flex>
              <v-flex xs6 style="text-align:right;">
                <v-btn @click="() => { edit_project__dialog = true; edit_project__item = project }" text><i class="fal fa-pencil"></i></v-btn>
              </v-flex>
            </v-layout>
            <ProjectView :project="project" :projects_data="projects" :index="index" :current_tab.sync="current_tab"></ProjectView>
          </v-container>
        </v-tab-item>
        <v-tab-item style="padding-bottom: 10px">
          <v-container>
            <v-form @submit="addProject">
              <v-layout row wrap style="margin:0;">
                <v-flex sm8 xs12>
                  <v-text-field
                    ref="add_project__project_name"
                    v-model="add_project__project_name"
                    label="Project name"
                    placeholder="ex. CR013"
                  ></v-text-field>
                </v-flex>
                <v-flex sm2 xs9>
                  <v-text-field
                    style="width: calc(100% - 10px); display: inline-block"
                    v-model="add_project__max_time"
                    label="Max hours"
                    placeholder="ex. 32.8"
                  ></v-text-field
                  ><span>h</span>
                </v-flex>
                <v-flex sm2 xs3 style="text-align: right"
                  ><v-btn style="margin-top: 10px" type="submit"
                    >add</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div v-else style="text-align: center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <EditProjectDialog v-model="edit_project__dialog" @edited="loadProjects" @deleted="loadProjects" :currentItem="edit_project__item"></EditProjectDialog>
    <EditClientDialog v-model="edit_client__dialog" @edited="loadProjects" @deleted="navToDashboard" :currentItem="edit_client__item"></EditClientDialog>
  </div>
</template>

<script>
import ProjectView from "../components/ProjectView";
import { storage } from "../schmucklicloud";
import EditProjectDialog from "../components/EditProjectDialog";
import EditClientDialog from "../components/EditClientDialog";

export default {
  data() {
    return {
      client_name: "",
      client_id: undefined,

      current_tab: 0,
      projects: [],
      l_projects: false,

      add_project__project_name: "",
      add_project__max_time: "",

      edit_project__dialog: false,
      edit_project__item: undefined,

      edit_client__dialog: false,
      edit_client__item: undefined,

      times: [],
    };
  },
  computed: {
    tab_projects() {
      var temp_projects = [];

      this.projects.forEach(function (project) {
        temp_projects.push({
          text: project.data.name,
          value: project.id,
          max_time: project.data.max_time
        });
      });

      return temp_projects;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // Clear
      this.l_projects = false;
      this.client_name = "";

      this.client_id = this.$route.params.client_id;
      this.getClientDetails(this.client_id);
      this.loadProjects();
    },
    async getClientDetails() {
      var response = await storage.getById("clients", this.client_id);
      if (response.isOK) {
        this.client_name = response.data.data.name;
      }
    },

    navToDashboard() {
      this.$router.replace("/d");
    },

    async loadProjects() {
      var response = await storage.get("projects", [
        {
          column: "client_id",
          operator: "==",
          value: this.client_id,
        }
      ], {
        column: "name",
        order: "asc"
      });
      if (response.isOK) {
        this.projects = response.data || [];

        // Has the project id parameter set? Navigate there
        if (this.$route.params.project_id) {
          this.current_tab = this.getTabIdByProjectId(this.$route.params.project_id);
        }
      }
      this.l_projects = true;
    },
    async addProject(e) {
      e.preventDefault();

      var max_time = parseFloat(this.add_project__max_time);
      if (Number.isNaN(max_time)) {
        this.$notify.toast(
          "Please type a decimal number for the maximum time."
        );
      } else {
        var response = await storage.insert("projects", {
          client_id: this.client_id,
          name: this.add_project__project_name,
          max_time: max_time,
        });
        if (response.isOK) {
          this.$notify.toast("Project has been saved.");
          this.add_project__project_name = "";
          this.add_project__max_time = "";
          this.loadProjects();
        } else {
          this.$notify.toast(response.message);
        }
      }
    },
    getTabIdByProjectId(project_id) {
      var tabid = 0;
      this.tab_projects.forEach(function(item, index) {
        if (parseInt(item.value) == parseInt(project_id)) {
          tabid = index;
        }
      });

      return tabid;
    }
  },
  watch: {
    $route() {
      this.init();
    },
    current_tab(val) {
      setTimeout(() => {
        if (val === this.tab_projects.length) {
          this.$refs.add_project__project_name.focus();
        }
      }, 200);
    },
  },
  components: {
    ProjectView,
    EditProjectDialog,
    EditClientDialog
  }
};
</script>

<style>
div.flex {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
