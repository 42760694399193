<template>
  <div>
    <v-dialog v-model="show" max-width="600px">
      <v-form @submit="editItem">
        <v-card>
          <v-card-title
            >Edit<v-spacer></v-spacer
            ><v-btn
              @click="deleteItem"
              text
              type="button"
              color="red"
              style="margin-top: 10px"
              ><i class="fal fa-trash"></i
            ></v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap style="margin: 0">
              <v-flex xs12 sm4>
                <v-text-field
                  label="Task No."
                  v-model="edit_time__taskno"
                  autocomplete="off"
                  placeholder="ex. 001"
                ></v-text-field>
              </v-flex>
              <v-flex sm8 xs12>
                <v-text-field
                  v-model="edit_time__description"
                  ref="add_time__description"
                  label="Description"
                  autocomplete="off"
                  placeholder="ex. Implemented bug fixes"
                ></v-text-field>
              </v-flex>
              <v-flex sm3 xs6>
                <v-text-field
                  v-model="edit_time__duration"
                  style="width: calc(100% - 10px); display: inline-block"
                  label="Duration"
                  autocomplete="off"
                  placeholder="ex. 4.5"
                ></v-text-field
                ><span>h</span>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex sm5 xs6>
                <v-text-field
                  autocomplete="off"
                  v-model="edit_time__date"
                  label="Date"
                  hint="yyyy/mm/dd"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-checkbox style="margin:10px 0 0 0;" label="Mark as 'Need to be changed'" color="orange" v-model="edit_time__mark_need_to_change"></v-checkbox>
            <v-checkbox style="margin:0;" label="Mark as 'Further action'" color="red" v-model="edit_time__mark_further_action"></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              type="button"
              @click="show = false"
              style="margin-top: 10px"
              >cancel</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn text type="submit" color="primary" style="margin-top: 10px">edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";
import dateFunctions from "../logic/date";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edit_time__date: "",
      edit_time__taskno: "",
      edit_time__description: "",
      edit_time__duration: "",
      edit_time__mark_need_to_change: false,
      edit_time__mark_further_action: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async editItem(e) {
      e.preventDefault();

      var duration = parseFloat(this.edit_time__duration);
      var date = dateFunctions.parseDate(this.edit_time__date);
      if (Number.isNaN(duration)) {
        this.$notify.toast("Please type a decimal number for the duration.");
      } else if (date === null) {
        this.$notify.toast(
          "Please provide a valid date in the format Year/Month/Date."
        );
      } else {
        var response = await storage.update("times", this.currentItem.id, {
          date: date,
          task_no: this.edit_time__taskno,
          description: this.edit_time__description,
          duration: this.edit_time__duration,
          marked_need_to_change: this.edit_time__mark_need_to_change ? 'true' : '',
          marked_further_action: this.edit_time__mark_further_action ? 'true' : ''
        });

        if (response.isOK) {
          this.show = false;
          this.$emit("edited");
          this.$notify.toast("The record has been edited.");
        } else {
          this.$notify.toast(response.message);
        }
      }
    },
    async deleteItem() {
      if (confirm("Do you really want to delete this record?")) {
        var response = await storage.delete("times", this.currentItem.id);
        if (response.isOK) {
          this.show = false;
          this.$notify.toast("The record has been deleted.");
          this.$emit("deleted", this.currentItem.id);
        } else {
          this.$notify.toast(response.message);
        }
      }
    },
  },
  watch: {
    show(open) {
      if (open) {
        this.edit_time__taskno = this.currentItem.task_no;
        this.edit_time__description = this.currentItem.description;
        this.edit_time__duration = this.currentItem.duration;
        this.edit_time__date = this.currentItem.date;
        this.edit_time__mark_need_to_change = this.currentItem.marked_need_to_change === 'true' ? true : false;
        this.edit_time__mark_further_action = this.currentItem.marked_further_action === 'true' ? true : false;
      }
    },
  },
};
</script>

<style>
</style>