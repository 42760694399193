var dateFunctions = {
    parseDate(date) {
        var oDate = new Date(date);
        var dateString = (
            oDate.getFullYear() +
            "/" +
            (oDate.getMonth() + 1 + "").padStart(2, "0") +
            "/" +
            (oDate.getDate() + "").padStart(2, "0")
        );
        if (dateString === "NaN/NaN/NaN") {
            return null;
        } else {
            return dateString;
        }
    },
    formatDate(parsedDateString) {
        var date = new Date(parsedDateString);
        return (date.getDate() + "").padStart(2, "0") + "." + ((date.getMonth() + 1) + "").padStart(2, "0") + "." + date.getFullYear();
    },
    getDayString(date) {
        var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return weekdays[date.getDay()];
    }
};

export default dateFunctions;