<template>
  <div>
    <v-dialog v-model="show" max-width="600px">
      <v-form @submit="editItem">
        <v-card>
          <v-card-title
            >Edit: {{ currentItem.text }}<v-spacer></v-spacer
            ><v-btn
              @click="deleteItem"
              text
              type="button"
              color="red"
              style="margin-top: 10px"
              ><i class="fal fa-trash"></i
            ></v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap style="margin: 0">
              <v-flex xs12 sm8>
                <v-text-field
                  label="Project name"
                  v-model="edit_project__name"
                  placeholder="ex. CR013"
                ></v-text-field>
              </v-flex>
              <v-flex sm4 xs9>
                <v-text-field
                  style="width: calc(100% - 10px); display: inline-block"
                  v-model="edit_project__max_time"
                  label="Max hours"
                  placeholder="ex. 32.8"
                ></v-text-field
                ><span>h</span>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              type="button"
              @click="show = false"
              style="margin-top: 10px"
              >cancel</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn text type="submit" color="primary" style="margin-top: 10px">edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edit_project__name: "",
      edit_project__max_time: ""
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async editItem(e) {
      e.preventDefault();

      var response = await storage.update("projects", this.currentItem.value, {
        name: this.edit_project__name,
        max_time: this.edit_project__max_time
      });

      if (response.isOK) {
        this.show = false;
        this.$emit("edited");
      } else {
        this.$notify.toast(response.message);
      }
    },
    async deleteItem() {
      if (confirm("Do you really want to delete this project?")) {
        var response = await storage.delete("projects", this.currentItem.value);
        if (response.isOK) {
          this.show = false;
          this.$notify.toast("The project has been deleted.");
          this.$emit("deleted", this.currentItem.id);
        } else {
          this.$notify.toast(response.message);
        }
      }
    },
  },
  watch: {
    show(open) {
      if (open) {
        this.edit_project__name = this.currentItem.text;
        this.edit_project__max_time = this.currentItem.max_time
      }
    },
  },
};
</script>

<style>
</style>