<template>
  <div>
    <v-dialog v-model="show" max-width="600px">
      <v-form @submit="editItem">
        <v-card>
          <v-card-title
            >Edit: {{ currentItem.name }}<v-spacer></v-spacer
            ><v-btn
              @click="deleteItem"
              text
              type="button"
              color="red"
              style="margin-top: 10px"
              ><i class="fal fa-trash"></i
            ></v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap style="margin: 0">
              <v-flex xs12 sm8>
                <v-text-field
                  label="Client name"
                  v-model="edit_client__name"
                  placeholder="ex. CR013"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              type="button"
              @click="show = false"
              style="margin-top: 10px"
              >cancel</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn text type="submit" color="primary" style="margin-top: 10px">edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edit_client__name: "",
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async editItem(e) {
      e.preventDefault();

      var response = await storage.update("clients", this.currentItem.id, {
        name: this.edit_client__name,
      });

      if (response.isOK) {
        this.show = false;
        this.$emit("edited");

        this.$notify.toast("The client has been updated.");

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.$notify.toast(response.message);
      }
    },
    async deleteItem() {
      if (confirm("Do you really want to delete this client?")) {
        var response = await storage.delete("clients", this.currentItem.id);
        if (response.isOK) {
          this.show = false;
          this.$notify.toast("The client has been deleted.");
          this.$emit("deleted", this.currentItem.id);
          setTimeout(()=>{ window.location.reload(); });
        } else {
          this.$notify.toast(response.message);
        }
      }
    },
  },
  watch: {
    show(open) {
      if (open) {
        this.edit_client__name = this.currentItem.name;
      }
    },
  },
};
</script>

<style>
</style>