<template>
  <div style="margin-top: 10px">
    <v-layout row wrap style="margin: 0 0 30px 0">
      <v-flex xs6 sm4>
        <Tile
          :loading="false"
          :value="projects_data[index].data.max_time + ' h'"
          name="Max time bookable"
        ></Tile>
      </v-flex>
      <v-flex xs6 sm4>
        <Tile
          :loading="false"
          :color="time_left < 0 ? 'red' : ''"
          :value="time_left + ' h'"
          name="Time left"
        ></Tile>
      </v-flex>
      <v-flex xs6 sm4>  
        <Tile
          :loading="false"
          :value="already_booked + ' h'"
          name="Time already booked"
        ></Tile>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-data-table
      :multi-sort="true"
      :loading="l_time"
      sort-by="date"
      :sort-desc="true"
      :items-per-page="5"
      :group-by="tab_records_group_by"
      :group-desc="tab_records_group_by === 'date' ? true : undefined"
      no-data-text="No records yet. Create one below to get this project started."
      :headers="time_headers"
      :items="table_times"
      @click:row="triggerSelectRecord"
    >
      <template v-slot:[`item.duration`]="{ item }">
        {{ item.duration }} h
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <i class="fad fa-info-circle" v-if="item.marked_need_to_change === 'true'" title="Need to be changed" style="color:orange;"></i>
        <i class="fad fa-info-circle" v-if="item.marked_further_action === 'true'" title="Further action" style="color:red;"></i>
        <span>{{ item.description }}</span>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ dateFunctions.formatDate(item.date) }}
      </template>
      <template v-slot:[`group.header`]="{ items }" style="width: 100%">
        <td colspan="2" style="padding-top:15px;">
          <p>{{ dateFunctions.formatDate(items[0].date) }}</p>
        </td>
        <td style="padding-top:15px;">
          <p>{{ getTotalDuration(items) }} h</p>
        </td>
      </template>
    </v-data-table>
    <div style="margin-bottom: 10px;">
      <v-btn text @click="tab_records_group_by = tab_records_group_by === undefined ? 'date' : undefined">Switch record view</v-btn>
    </div>
    <v-divider></v-divider>
    <v-form @submit="addTime">
      <h3 style="margin-top: 15px">Add record</h3>
      <v-layout row wrap style="margin: 0">
        <v-flex sm2 xs4>
          <v-text-field
            label="Task No."
            v-model="add_time__taskno"
            placeholder="ex. 001"
            autocomplete="off"
          ></v-text-field>
        </v-flex>
        <v-flex sm7 xs8>
          <v-text-field
            v-model="add_time__description"
            ref="add_time__description"
            label="Description"
            placeholder="ex. Implemented bug fixes"
          ></v-text-field>
        </v-flex>
        <v-flex sm3 xs9>
          <v-text-field
            v-model="add_time__duration"
            style="width: calc(100% - 10px); display: inline-block"
            label="Duration"
            placeholder="ex. 4.5"
            autocomplete="off"
          ></v-text-field
          ><span>h</span>
        </v-flex>
        <v-flex xs7 sm7>
          <v-text-field
            autocomplete="off"
            v-model="add_time__date"
            label="Date"
            hint="yyyy/mm/dd"
            persistent-hint
            prepend-icon="mdi-calendar"
          ></v-text-field>
        </v-flex>
        <v-flex xs3 sm5 style="text-align: right">
          <v-btn type="submit" style="margin-top: 10px">add</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <EditRecordDialog
      v-model="edit_time__dialog"
      @edited="loadTimes"
      @deleted="removeItem"
      :currentItem="edit_time__item"
    ></EditRecordDialog>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";
import EditRecordDialog from "../components/EditRecordDialog";
import dateFunctions from "../logic/date";

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    projects_data: {
      type: Array,
      required: true,
    },
    current_tab: {
      type: Number,
    },
  },
  data() {
    return {
      times: [],
      time_headers: [
        {
          text: "Date",
          value: "date",
          width: "15%",
        },
        {
          text: "Task No.",
          value: "task_no",
          width: "15%",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Duration",
          value: "duration",
          width: "15%",
        },
      ],
      l_time: true,

      add_time__date_picker_modal: false,

      add_time__taskno: "",
      add_time__description: "",
      add_time__duration: "",
      add_time__date: dateFunctions.parseDate(new Date()),

      edit_time__dialog: false,
      edit_time__item: undefined,

      tab_records_group_by: 'date',

      dateFunctions: dateFunctions
    };
  },
  computed: {
    table_times() {
      var temp_times = [];

      this.times.forEach(function (time) {
        temp_times.push({
          id: time.id,
          date: time.data.date,
          task_no: time.data.task_no,
          description: time.data.description,
          duration: time.data.duration,
          marked_need_to_change: time.data.marked_need_to_change,
          marked_further_action: time.data.marked_further_action,
        });
      });

      return temp_times;
    },
    already_booked() {
      var total = 0;
      this.times.forEach((time) => {
        total += parseFloat(time.data.duration);
      });

      return total;
    },
    time_left() {
      return (
        parseFloat(this.projects_data[this.index].data.max_time) -
        this.already_booked
      );
    },
  },
  mounted() {
    this.loadTimes();
  },
  methods: {
    async loadTimes() {
      var response = await storage.get(
        "times",
        [
          {
            column: "project_id",
            operator: "==",
            value: this.projects_data[this.index].id,
          },
        ],
        {
          column: "date",
          order: "desc",
        }
      );

      if (response.isOK) {
        this.times = response.data || [];
      } else {
        this.$notify.toast(response.message);
      }

      this.l_time = false;
    },
    triggerSelectRecord(item) {
      this.edit_time__dialog = true;
      this.edit_time__item = item;
    },
    async addTime(e) {
      e.preventDefault();

      var duration = parseFloat(this.add_time__duration);
      var date = dateFunctions.parseDate(this.add_time__date);
      if (Number.isNaN(duration)) {
        this.$notify.toast("Please type a decimal number for the duration.");
      } else if (date === null) {
        this.$notify.toast(
          "Please provide a valid date in the format Year/Month/Date."
        );
      } else {
        var response = await storage.insert("times", {
          date: date,
          task_no: this.add_time__taskno,
          description: this.add_time__description,
          duration: duration,
          project_id: this.projects_data[this.index].id,
        });

        if (response.isOK) {
          this.$notify.toast("The record has been saved.");
          this.add_time__description = "";
          this.add_time__duration = "";
          this.loadTimes();
        } else {
          this.$notify.toast(response.message);
        }
      }
    },
    removeItem(id) {
      this.times.forEach((time, index) => {
        if (time.id === id) {
          this.times[index] = null;
        }
      });
      this.times = this.times.filter((x) => !!x);
    },
    getTotalDuration(items) {
      var total = 0;

      items.forEach(function(item) {
        total += parseFloat(item.duration);
      });
      return total;
    }
  },
  watch: {
    current_tab(val) {
      if (this.index === val) {
        // Checks if it is currently selected
        this.$refs.add_time__description.focus();
        this.loadTimes();
      }
    },
  },
  components: {
    EditRecordDialog,
  },
};
</script>

<style>
</style>